<template>
    <div class="bg-slate-100">
        <header class="flex items-center p-4 gap-1 border-b ">
            <Logo class="text-primary-500 w-[5rem]" />
            <Icon name="heroicons:chevron-right-solid" class="text-primary-500" />
            <span class=" text-primary-500">Gen AI Case study</span>
        </header>
        <div class="p-4 relative">
            <div class="flex justify-between">
                <span class="text-3xl">Scaled <strong
                        class="font-bold text-primary-500 underline">high-accuracy</strong> document answering for a
                    European financial institution (~40B market cap)</span>
            </div>
            <div class="relative w-full p-4 rounded-lg justify-center mt-8">
                <div
                    class="flex self-end ml-auto bg-white max-w-max p-2 items-center gap-2 rounded-lg text-primary-500 font-bold">
                    <Icon name="heroicons:trophy" class="text-xl" />
                    <span>Results</span>
                </div>
                <div>

                </div>
                <div class="flex gap-3 mt-8 text-pretty">
                    <div
                        class="bg-white/50 border border-white rounded-lg p-4 flex-1 flex flex-col items-center justify-center text-center gap-2">
                        <span class="font-bold text-4xl">50 mins</span>
                        <span class="text-sm">Estimated daily productivity gain per customer representative</span>
                    </div>
                    <div
                        class="bg-white/50 border border-white rounded-lg p-4 flex-1 flex flex-col items-center justify-center text-center gap-2">
                        <span>
                            <Icon name="heroicons:face-smile-solid" class="text-green-500 text-[3rem]" />
                        </span>
                        <span class="text-sm">Customer reps reporting higher satisfaction</span>
                    </div>
                    <div
                        class="bg-white/50 border border-white rounded-lg p-4 flex-1 flex flex-col items-center justify-center text-center gap-2">
                        <span class="font-bold text-4xl">-50%</span>
                        <span class="text-sm">The anticipated cost of the solution in production</span>
                    </div>

                </div>
            </div>
            <div class="w-full relative z-10 mt-4">
                <div class="w-full h-[1px] bg-gray-300 absolute top-1/2 -z-10"></div>
                <span class="block mx-auto z-10 bg-slate-100 max-w-max px-4 font-bold uppercase">Challenge</span>
            </div>
            <div
                class="absolute right-8 flex bg-white max-w-max p-2 items-center gap-2 rounded-lg text-primary-500 font-bold">
                <Icon name="heroicons:puzzle-piece" class="text-xl" />
                <span>Issue</span>
            </div>
            <p class="mt-4 max-w-[40rem] text-center mx-auto block">Providing customer service representatives with
                timely and accurate answers to client inquiries is costly and resource draining for retail banks.
                Documents are being updated constantly and it is hard for customer representatives to keep up with the
                pace of changes.

            </p>

            <div class="flex gap-8">
                <div class="relative w-full p-4 rounded-lg flex justify-center mt-8">

                    <div class="flex gap-3">
                        <div
                            class="bg-white/50 border border-white rounded-lg p-4 flex-1 flex flex-col items-center justify-center text-center gap-2">
                            <span class="font-bold text-4xl">12-15k</span>
                            <span class="text-sm">Daily queries</span>
                        </div>
                        <div
                            class="bg-white/50 border border-white rounded-lg p-4 flex-1 flex flex-col items-center justify-center text-center gap-2">
                            <span class="font-bold text-4xl">8k</span>
                            <span class="text-sm"># of documents</span>
                        </div>
                        <div
                            class="bg-white/50 border border-white rounded-lg p-4 flex-1 flex flex-col items-center justify-center text-center gap-2">
                            <span class="font-bold text-4xl">50+</span>
                            <span class="text-sm"># weekly changes to the database</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full relative z-10 mt-4">
                <div class="w-full h-[1px] bg-gray-300 absolute top-1/2 -z-10"></div>
                <span class="block mx-auto z-10 bg-slate-100 max-w-max px-4 font-bold uppercase">Solution</span>
            </div>
            <p class="mt-4 max-w-[40rem] text-center mx-auto block">Pathway offered a containerized solution to retrieve
                insights from the bank’s diverse data sources

            </p>
            <img src="/assets/solutions/ai-slides-diagram.svg" alt="RAG Slides Search Use Case diagram"
                class="mx-auto px-4 mt-8" />
            <div class="flex mt-8 gap-8 ">
                <div class="w-full relative">
                    <div
                        class="absolute right-4 flex bg-white max-w-max p-2 items-center gap-2 rounded-lg text-primary-500 font-bold">
                        <Icon name="heroicons:magnifying-glass-16-solid" class="text-xl" />
                        <span>Product</span>
                    </div>
                    <div class="mt-2">
                        <span class="block font-bold text-xl">Pathway's AI Pipeline</span>
                        <List class="list-disc list-inside mt-2 text-pretty text-slate-600 flex flex-col mt-2"
                            type="success_solid" listClass="items-start">
                            <li>Live Data connectors: Ingested data from required sources</li>
                            <li>Parsing: Extracted text, tables and metadata, from complex unstructured data</li>
                            <li>Indexing: efficient in-memory index to adapt automatically to changing data

                            </li>
                        </List>
                    </div>
                </div>

            </div>

        </div>



    </div>
</template>
